// eslint-disable-next-line import/no-anonymous-default-export
const environment = {
  apiUrl: "https://beeng.cybersoft.edu.vn/api",
  // apiUrl: "http://192.168.0.180:3000/api",
  faceBookAppId: "464905814949590",
  env: "dev",
  production: false,
  enableCheat: true,
  version: "1.0.0",
};
export default environment;
